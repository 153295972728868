<template>
  <img
    alt="Logo"
    :src="Logo"
    :class="{'cursor-pointer':clickable}"
    @click="onClick"
  >
</template>
<script setup>
import { useRouter } from 'vue-router'
import Logo from '@/assets/common/logo.gif'

const router = useRouter()

const props = defineProps({
  dark: {
    type: Boolean,
    default: true
  },
  clickable: {
    type: Boolean,
    default: false
  }
})

function onClick () {
  props.clickable && router.push('/')
}
</script>
